@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap");
@layer base {
  /* h1 {
    @apply flex justify-center font-bold text-4xl md:text-5xl leading-tight m-4;
  }
  h2 {
    @apply mb-2 text-2xl tracking-tight font-bold;
  }
  h3 {
    @apply mb-1 text-xl tracking-tight font-bold;
  }
  */
  /* html {
    @apply font-ibmplex;
  } */
}
@layer components {
  .full-center {
    @apply grid h-full place-items-center;
  }
}
/* @layer components {
  
  .btn-main {
    @apply btn-primary text-white rounded;
  }
  .badge-main {
    @apply badge-primary text-white rounded;
  }
} */
